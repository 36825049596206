import { withPrefix } from 'gatsby';

import { css } from '@emotion/react';
import {
  black,
  blue5,
  charcoal,
  darkOverlay,
  gray4,
  gray5,
  gray7,
  tertiaryTransparent,
  white,
} from '@fire/_color';
import {
  borderRadius,
  buttonDefault,
  closeButton,
  dateInputLabel,
  filterBarBoxShadow,
  primaryButton,
  zIndex,
} from '@fire/_const';
import { bold, fontFace, fontSizeBody, fontSizeMedium } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const form = css`
  position: relative;
  display: flex;
  flex-direction: column;
  order: 0;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  margin: 0 auto;

  ${media.xl} {
    order: 1;
    width: 100%;
    max-width: 750px;
    margin-bottom: 16px;
  }

  [type='date']::-webkit-inner-spin-button,
  [type='date']::-webkit-clear-button {
    display: none;
  }

  /* fix click behavior on desktop */
  [type='date']::-webkit-calendar-picker-indicator {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }
`;

export const header = css`
  background: ${white};

  ${media.md} {
    background: ${gray5};
  }
`;

export const content = css`
  padding-bottom: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.md} {
    position: relative;
  }
`;

export const input = css`
  ${fontFace};
  appearance: none;
  background-color: ${white};
  border: unset;
  border-radius: 8px;
  color: ${charcoal};
  display: flex;
  flex-direction: row;
  font-size: ${fontSizeBody};
  font-weight: ${bold};
  height: 40px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &[disabled] {
    color: ${gray7};
    cursor: not-allowed;
  }

  ${media.md} {
    font-size: ${fontSizeMedium};
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: ${white} url(${withPrefix('icons/arrowDark.svg')});
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    color: ${charcoal};
    border: 1px solid ${charcoal};
    border-radius: 8px;
    ${fontFace};
    font-size: ${fontSizeBody};
    font-weight: ${bold};
    padding: 0 16px;
    width: 100%;
  }
`;

export const realInput = css`
  border-radius: 0;
  cursor: pointer;
  padding-left: 16px;
`;

export const cancelBorderRight = css`
  border-right: none;
`;

export const fullWidth = css`
  span {
    width: 100%;
  }
`;

export const row = css`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 16px 24px 0;

  ${media.xl} {
    padding: 24px 0 0;
  }

  span:not([class*='buttonContent']) {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:only-child {
      width: 100%;
    }
  }

  label {
    font-size: ${fontSizeMedium};

    span {
      width: 100%;
    }
  }

  input {
    border: unset;
  }

  .DateRangePickerInput {
    height: 54px;
  }
`;

export const formRow = css`
  label: formRow;
`;

export const withIcon = css`
  flex-direction: row;
  align-items: center;
  background-color: ${white};
  border-radius: 8px;

  img {
    margin-left: 8px;
  }
`;

export const labels = css`
  ${dateInputLabel}
  left: 45px;
  top: 6px;

  > label:first-of-type {
    width: 44%;
  }
`;

export const rowTitle = css`
  justify-content: flex-start;
  margin: 0;
  padding: 16px 24px;

  h3 {
    font-size: 26px;
  }

  ${media.md} {
    margin: 7px 24px 25px 24px;

    h3 {
      font-size: 20px;
    }
  }
`;

export const visibleOn = mobile => css`
  display: ${mobile ? 'block' : 'none'};

  ${media.md} {
    display: ${mobile ? 'none' : 'block'};
  }
`;

export const guestPicker = css`
  min-height: 54px;
  ${media.md} {
    padding-top: 12px;
  }

  input {
    cursor: pointer;
  }
`;

export const guestContainer = css`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndex.layerTop};

  ${media.md} {
    height: auto;
    position: absolute;
    top: 175px;
    z-index: 1;
  }
  & > div {
    padding: 48px 24px;
    background: ${white};
    height: 100vh;
    ${media.md} {
      height: unset;
      padding: 24px;
      box-shadow: 0 2px 6px 0 ${darkOverlay};
      border-radius: 8px;
    }
  }
`;

export const guestPickerWrapper = css`
  position: absolute;

  .guestPickerContainer {
    ${media.md} {
      margin: 0 0;
      width: 260px;
    }
  }
`;

export const pickerRow = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  p {
    font-weight: ${bold};
  }
`;

export const filterBar = css`
  align-items: flex-end;
  background-color: ${gray4};
  border-top: 1px solid ${gray4};
  bottom: 0;
  display: flex;
  flex-direction: row;
  left: 0;
  padding: 12px 24px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${zIndex.layerTop};
  ${filterBarBoxShadow};
`;

export const buttonAndDays = css`
  text-align: center;
  width: 100%;

  p {
    font-size: ${fontSizeMedium};
    margin-bottom: 6px;
  }
`;

export const resetButton = css`
  ${fontFace};
  background: transparent;
  border: none;
  color: ${charcoal};
  font-size: ${fontSizeBody};
  margin-right: 56px;
  padding: 0;
  text-decoration: underline;

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  actions & {
    text-align: left;
  }
`;

export const kidsAgesTitle = css`
  padding: 16px 0px;
`;

export const ageFields = css`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${media.md} {
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }
`;

export const actionBox = css`
  display: none;

  ${media.md} {
    display: flex;
    margin: 32px 0 0;

    button {
      cursor: pointer;
    }
  }
`;

export const datePicker = css`
  width: 100%;
  background-color: ${white};
  border-radius: 8px;
  display: flex;
  position: relative;

  > div:last-of-type {
    width: 100%;
  }

  .DateInput_input {
    color: ${black};
  }

  .DateRangePickerInput__withBorder {
    border-radius: 8px;
  }

  .DateRangePickerInput__withBorder,
  .DateInput:first-of-type {
    border: unset;
  }

  .DateInput:last-of-type {
    :before {
      content: '';
      width: 3px;
      height: 25px;
      background-color: ${charcoal};
      position: absolute;
      top: 15px;
    }
  }

  .DateInput_input__focused {
    border: unset;
  }

  ${media.md} {
    padding: 0;
  }
`;

export const calendarIconWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const backButton = css`
  background: ${white};
  border-radius: 50%;
  display: flex;
  height: 34px;
  justify-content: center;
  left: 4px;
  margin-top: -69px;
  position: absolute;
  top: 4px;
  transition: 0.2s background;
  width: 34px;

  button {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  img {
    margin-right: 4px;
    width: 20px;
    transform: rotate(90deg);
  }

  &:active {
    background: ${tertiaryTransparent};
  }

  ${media.md} {
    display: none;
  }
`;

export const nav = css`
  width: 25px;

  &:hover {
    img {
      filter: brightness(0.5);
    }
  }

  img {
    height: 25px;
    position: absolute;
    top: 20px;
    width: 25px;
  }
`;

export const next = css`
  ${nav}

  img {
    position: absolute;
    right: 30px;
    transform: rotate(180deg);
  }
`;

export const prev = css`
  ${nav}
  img {
    left: 28px;
  }
`;

export const calendarInfo = css`
  background: ${gray5};
  border-top: 1px solid ${gray5};
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  left: 0;
  padding: 12px 24px;
  position: fixed;
  width: 100%;

  ${media.md} {
    background: transparent;
    border: 0;
    flex-flow: row;
    position: relative;
    padding-top: 0;
  }
`;

export const nights = css`
  align-self: flex-end;
  font-size: ${fontSizeMedium};
  margin-bottom: 6px;
  text-align: center;
  width: 60%;

  ${media.md} {
    align-self: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 24px;
    text-align: left;
    width: 50%;
  }
`;

export const apply = css`
  ${buttonDefault}
  ${primaryButton}
`;

export const cancel = css`
  ${resetButton}
  cursor: pointer;
`;

export const actions = css`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;

  ${media.md} {
    width: 50%;
  }

  .css-${apply.name} {
    width: 60%;

    ${media.md} {
      width: auto;
    }
  }
`;

export const datePickerRow = css`
  ${row}
  ${media.xl} {
    padding: 8px 0 0;
  }
`;

export const availabilityMessage = css`
  flex-direction: column-reverse;
  font-size: ${fontSizeMedium};
  justify-content: center;

  ${media.xl} {
    margin-left: 0;
  }
`;

export const prices = css`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  height: 100%;
  padding: 16px;
  border-radius: 10px;

  ${media.xl} {
    background-color: ${white};
    gap: 20px;
  }
`;

export const pricePerNight = css`
  ${media.xl} {
    white-space: normal;
  }
`;

export const priceMobile = css`
  display: flex;

  ${media.xl} {
    display: none;
  }
`;

export const priceDesktop = css`
  display: none;
  justify-content: space-between;
  align-items: center;

  ${media.xl} {
    display: flex;
  }
`;

export const priceDesktopPrice = css`
  font-size: 23px;
  line-height: 30px;
  font-weight: ${bold};
`;

export const bottomBox = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 15px;
`;

export const close = css`
  ${closeButton};

  align-items: center;
  display: flex;
  justify-content: center;
`;

export const noAvailabilitiesInfo = css`
  align-items: center;
  background: ${blue5};
  border-radius: ${borderRadius};
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  padding: 10px;

  span {
    min-width: max-content;
  }
`;

export const logoWrapper = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;
